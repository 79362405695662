import { VFXProvider, VFXImg } from 'react-vfx'
import { TypeAnimation } from 'react-type-animation';

import './Home.scss';
import logo from './logo.svg';
import { logoShader } from './logoShader';


export const Home = () => (
  <div className="home">
    <header className="header">
      <VFXProvider>
        <VFXImg
          src={logo}
          className="app-logo"
          alt="SWK Prod"
          shader={logoShader}
          overflow={1000}
        />
      </VFXProvider>

      <TypeAnimation
        sequence={[
          'Let\'s bring your ideas to life together\n',
          1000,
          'Let\'s bring your ideas to life together\n.',
          1000,
          'Let\'s bring your ideas to life together\n..',
          1000,
          'Let\'s bring your ideas to life together\n...',
          1000
        ]}
        wrapper="p"
        speed={75}
        className="catch-phrase"
        repeat={Infinity}
      />

      <div className="hashtag">
        <div>
          <code>#WebApp</code>
          <code>#MobileApp</code>
        </div>

        <div>
          <code>#Multimedia</code>
          <code>#Music</code>
          <code>#Events</code>
        </div>
      </div>
    </header>
  </div>
);
